<template>
  <b-modal size="md" v-model="computedShowModal" :title="'Meeting Info - ' + schedule.name" hide-footer @hidden="closeModal">
    <table class="table table-bordered">
      <tr class="p-2">
        <td width="25%">Type</td>
        <td>{{ schedule.type }}</td>
      </tr>
      <tr class="p-2">
        <td width="25%">Name</td>
        <td>{{ schedule.name }}</td>
      </tr>
      <tr>
        <td width="25%">Host</td>
        <td>{{ schedule.user ? schedule.user.name : '-' }}</td>
      </tr>
      <tr>
        <td width="25%">Team</td>
        <td>
          {{ schedule.user_contributed_str ? schedule.user_contributed_str.join(', ') : '-' }}
        </td>
      </tr>
      <tr>
        <td width="25%">Created Date</td>
        <td>{{ schedule.created_at_str }}</td>
      </tr>
      <tr>
        <td width="25%">Schedule Date</td>
        <td>{{ schedule.scheduled_at_str }}</td>
      </tr>
      <tr>
        <td width="25%">Description</td>
        <td>{{ schedule.description }}</td>
      </tr>
      <tr v-if="schedule.type === 'meeting'">
        <td width="25%">Lead</td>
        <td>{{ schedule.leads_board.name }}</td>
      </tr>
      <tr>
        <td width="25%">Status</td>
        <td>
          <p class="leading-tight rounded-sm p-1 mt-0 mb-1 cursor-pointer"
            :class="schedule.class">
            {{ schedule.status_str }}
          </p>
        </td>
      </tr>
    </table>
    <div class="d-flex d-flex justify-content-between mt-2">
      <div class="d-flex">
        <el-button size="small" type="primary" icon="el-icon-edit" class="p-2"
        v-if="checkDate()" @click="handlerEditSchedule" :loading="loading.edit_schedule">
        </el-button>
        <el-button size="small" type="danger" icon="el-icon-delete" class="p-2"
        v-if="checkDate()" @click="handlerCancelSchedule" :loading="loading.cancel_schedule">
        </el-button>
      </div>
      <div>
        <el-button size="small" @click="closeModal" type="secondary">{{ $t('general.close') }}</el-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment';
import leadsAPI from '../../../api/leads';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'ScheduleInfo',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    schedule: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      show_modal: false,
      loading: {
        cancel_schedule: false,
      },
      relation: {
        am: 'am',
        ar: 'sy',
        az: 'az',
        bn: 'bd',
        be: 'be',
        ca: 'es-ca',
        cs: 'cz',
        de: 'de',
        el: 'gr',
        en: 'gb',
        es: 'es',
        et: 'et',
        fa: 'ir',
        fr: 'fr',
        bg: 'bg',
        ha: 'ng',
        hi: 'in',
        hu: 'hu',
        hy: 'am',
        it: 'it',
        ja: 'jp',
        jv: 'id',
        km: 'km',
        ko: 'kr',
        lv: 'lv',
        mr: 'in',
        ms: 'my',
        pl: 'pl',
        pt: 'pt',
        ro: 'ro',
        ru: 'ru',
        sw: 'ke',
        ta: 'lk',
        te: 'in',
        th: 'th',
        tr: 'tr',
        uk: 'ua',
        uz: 'uz',
        vi: 'vn',
        zh: 'cn',
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    userProfile() {
      return this.$store.state.auth.userProfile || {};
    },
    computedShowModal: {
      get() {
        return this.show_modal;
      },
      set(val) {
        this.show_modal = val;
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit('onClose');
    },
    checkDate() {
      let cancel = false;
      if (this.userProfile.id === this.schedule.user?.id) {
        if (this.schedule.type === 'meeting') {
          if (moment().format('YYYY-MM-DD HH:mm') <= moment(this.schedule.schedule_at).format('YYYY-MM-DD HH:mm')) {
            cancel = true;
          }
        }
      }
      return cancel;
    },
    handlerEditSchedule() {
      this.$emit('onEdit');
    },
    handlerCancelSchedule() {
      this.$confirm(this.$t('schedule.confirm_cancel_schedule'), this.$t('general.confirmation'), {
        type: 'warning',
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: (action, instance, cb) => {
          if (action === 'confirm') {
            this.doCancelSchedule();
          }
          cb();
        },
      });
    },
    doCancelSchedule() {
      this.loading.cancel_schedule = true;
      leadsAPI.delete_schedule(
        this.activeWorkspace._id,
        this.schedule.id,
      ).then(async (res) => {
        await popupErrorMessages(res);
        this.$message({
          type: this.$t('success'),
          message: this.$t('schedule.success_cancel_schedule'),
        });
        this.$parent.getList();
        this.closeModal();
      }).catch(async (err) => {
        await popupErrorMessages(err);
        this.loading.cancel_schedule = false;
      });
      this.loading.cancel_schedule = false;
    },
  },
  watch: {
    showModal() {
      this.show_modal = this.showModal;
    },
  },
};
</script>
